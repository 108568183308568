
    var riot = require('riot')
    
riot.tag2('mailchimp', '<a onclick="{tags.modal1.show}" class="btn btn-primary">Sign Up</a> <modal name="modal1" title="Sign up to the HMK Mailing List" onclose="{true}" onsave="{send}" savetext="Sign Up" closetext="Cancel" class="mailchimp-modal loading"> <form action="https://hmkdirect.us13.list-manage.com/subscribe/post" method="post" id="chimpform" class="form-horizontal"> <input type="hidden" name="u" value="b83d5aceadc93f370c2e988c3"> <input type="hidden" name="id" value="5d30a72829"> <div class="row form-group"> <label for="MERGE0" class="col-xs-12 col-md-4">Email address</label> <div class="col-xs-12 col-md-8"> <input name="MERGE0" placeholder="Email" required="required" class="form-control" type="email"> </div> </div> <div class="row form-group"> <label for="MERGE1" class="col-xs-12 col-md-4">First name</label> <div class="col-xs-12 col-md-8"> <input type="text" name="MERGE1" placeholder="First name" required="required" class="form-control"> </div> </div> <div class="row form-group"> <label for="MERGE2" class="col-xs-12 col-md-4">Last name</label> <div class="col-xs-12 col-md-8"> <input type="text" name="MERGE2" placeholder="Last name" required="required" class="form-control"> </div> </div> </form> </modal>', '', '', function(opts) {
this.send = (function(_this) {
  return function() {
    return document.querySelector("#chimpform").submit();
  };
})(this);
});
    
  