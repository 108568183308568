
    var riot = require('riot')
    
riot.tag2('counter', '<h2>{Math.round(total)}{opts.suffix}</h2> <h3>{opts.subtitle}</h3>', 'counter, [riot-tag="counter"] { min-height: calc(7vw + 80px); display: block; padding-top: 4vw; text-align: center; } counter h2, [riot-tag="counter"] h2 { font-size: calc(3.6vw + 20px); color: #333; text-align: center; } counter h3, [riot-tag="counter"] h3 { font-size: calc(0.8vw + 10px); color: #333; }', '', function(opts) {
this.counterIsPlaying = false;

this.checkScroll = (function(_this) {
  return function() {
    var div_height, div_top, target;
    _this.divs = document.querySelectorAll('[riot-tag="counter"]');
    div_top = _this.divs[0].getBoundingClientRect().top;
    div_height = _this.divs[0].getBoundingClientRect().height;
    target = window.innerHeight - div_height;
    if (div_top < target) {
      if (_this.counterIsPlaying !== true) {
        _this.interval = setInterval(function() {
          return requestAnimationFrame(_this.update);
        }, 20);
        return _this.counterIsPlaying = true;
      }
    }
  };
})(this);

this.on('mount', function() {
  this.total = 0;
  return ['scroll', 'load', 'resize'].forEach((function(_this) {
    return function(type) {
      return window.addEventListener(type, _this.checkScroll);
    };
  })(this));
});

this.on('update', function() {
  var inc;
  inc = opts.total / 120;
  if (this.total < opts.total) {
    return this.total += inc;
  } else if (this.interval) {
    clearInterval(this.interval);
    return this.interval = null;
  }
});
});
    
  