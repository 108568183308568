
    var riot = require('riot')
    
riot.tag2('faqs', '<yield></yield>', '.question { cursor: pointer; background: #f2f3f4; padding: 10px; padding-left: 30px; border-radius: 5px; font-size: 18px; position: relative; } .question:before { content: \'\\f0da\'; display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; margin-right: 10px; position: absolute; top: 10px; left: 10px; } .question.opened:before { content: \'\\f0d7\'; } .answer { max-height: 0; overflow: hidden; -webkit-transition: 0.5s all; -moz-transition: 0.5s all; -o-transition: 0.5s all; -ms-transition: 0.5s all; transition: 0.5s all; } .opened { max-height: inherit; -webkit-transition-duration: 1s; -moz-transition-duration: 1s; -o-transition-duration: 1s; -ms-transition-duration: 1s; transition-duration: 1s; }', 'onclick="{handleClick}"', function(opts) {
this.on('mount', function() {
  return [].slice.call(this.root.querySelectorAll('h3')).forEach((function(_this) {
    return function(question, idx) {
      var subtags, wrapper;
      subtags = _this.findAnswer(question);
      wrapper = document.createElement('div');
      wrapper.className = "answer container" + idx;
      question.setAttribute("openerid", idx);
      question.className += " question";
      subtags.forEach(function(node) {
        return wrapper.appendChild(node);
      });
      return question.parentNode.insertBefore(wrapper, question.nextSibling);
    };
  })(this));
});

this.findAnswer = (function(_this) {
  return function(elem) {
    var answer, answers;
    answer = elem != null ? elem.nextSibling : void 0;
    answers = [];
    if ((answer != null) && !/H[23]/.test(answer.tagName)) {
      answers.push(answer);
      answers = answers.concat(_this.findAnswer(answer));
    }
    return answers;
  };
})(this);

this.handleClick = (function(_this) {
  return function(e) {
    var id, open;
    if (e.target.tagName.toLowerCase() !== "h3") {
      return true;
    }
    id = e.target.getAttribute('openerid');
    open = e.target.className.match(/opened/) ? true : false;
    [].slice.call(_this.root.querySelectorAll('.opened')).forEach(function(node) {
      return node.className = node.className.replace(/ ?opened/, '');
    });
    if (!open) {
      _this.root.querySelector('.container' + id).className += " opened";
    }
    if (!open) {
      return _this.root.querySelector('[openerid="' + id + '"]').className += " opened";
    }
  };
})(this);
});
    
  