window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')

require 'riot-kit/lib/tabs'

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'

require './tags/counter.tag'
require './tags/gmap.tag'
require './tags/duomap.tag'
require './tags/availability-cal.tag'
# require './tags/pricing.tag'
# require './tags/memgraph.tag'
require './tags/auth/signin.tag'
require './tags/auth/signup.tag'
require './tags/profile.tag'
require './tags/productfilter.tag'
require('smoothscroll-polyfill').polyfill()
# require './tags/dropdown.tag'
require './tags/faqs.tag'
require './tags/mailchimp.tag'

require './tags/siemens-register.tag'


$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'owl.carousel'

addedWidgets = []

window.verticalWidgetCallback = ->
  addedWidgets.push("full-page-widget")
  return if !document.querySelector("#full-page-widget")
  window.verticalWidget('full-page-widget',{
    store: 'hmkdirect-com',
    primaryClr: '#f47e27',
    layout:'fullWidth',
    height: 500,
    numReviews: 21
  })

window.reviewsBadgeCallback = ->
  addedWidgets.push("badge-250")
  return if !document.querySelector("#badge-250")
  window.reviewsBadge('badge-250',{store:'hmkdirect-com',primaryClr:'#3fa9f5',neutralClr: '#3fa9f5',starsClr:'#fff',textClr:'#fff'})


window.verticalWidgetCallback() if(addedWidgets.indexOf("full-page-widget")==-1 && window.verticalWidget)
window.reviewsBadgeCallback() if(addedWidgets.indexOf("badge-250")==-1 && window.reviewsBadge)

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')



topbutton = document.querySelector('.topbutton')
topbutton.addEventListener 'click', ->
  document.querySelector('#page-top').scrollIntoView({behavior:'smooth'})

# add active class to any links which match current url
[].slice.call(document.querySelectorAll(".nav.navbar-nav.navbar-right a")).forEach (link)->
  linkpath = "/"+link.href.split("/")[3...].join("/")
  link.className+=" active" if window.location.pathname == linkpath # add active for exact match

[].slice.call(document.querySelectorAll(".subnav-child .expandable span")).forEach (subnav)->
  if subnav
    if subnav.parentNode.href # set drop down to show
      linkpath = "/"+subnav.parentNode.href.split("/")[3...].join("/") # remove domain from url
      subnav.parentNode.parentNode.className+=" show" if window.location.pathname.match(linkpath) # show if path matches on load
      
    subnav.addEventListener "click",(event)->
      event.preventDefault()
      event.stopPropagation()
      tg = event.target.parentNode.parentNode
      if tg?.className.match(/show/)
        tg.className = tg.className.replace(/ ?show ?/,"")
      else
        tg.className+=" show"
        

# loads css async
[].slice.call(document.querySelectorAll("link[media=none]")).forEach (stl)-> stl.setAttribute("media","all")
[].slice.call(document.querySelectorAll('input')).forEach (input)-> 
  input.setAttribute("style","display:none") if input.getAttribute("name")?.match(/^duoreform/)

checkScroll = ->
  [].forEach.call fullimgs,(fimg,index)->
    fi_top = fimg.getBoundingClientRect().top
    fi_height = fimg.getBoundingClientRect().height
    target = window.innerHeight - fi_height
    if fi_top < target
      if carouselIsPlaying[index]!=true
        $(clists[index]).trigger('play.owl.autoplay')
      carouselIsPlaying[index] = true

checkSliders = ->
  [].forEach.call sliders,(slider)->
    s_top = slider.getBoundingClientRect().top
    target = window.innerHeight - 100
    if s_top < target
      slider.className = slider.className.replace(/ ?vis ?/,'') + ' vis'

equalise = ->
  h = fic.clientHeight
  fi.setAttribute('style',"height:"+h+"px")
  img = fi.querySelector('.img-responsive')
  src = img.getAttribute('src')
  img.parentNode.setAttribute('style','position:absolute;height:100%;width:100%;top:0;left:0;right:0;bottom:0;background-image:url("'+src+'");background-size:cover;background-position:center center;')
  img.setAttribute('style','display:none')

updateImage = (e)->
  img = e.currentTarget.querySelectorAll('.owl-item .item')[e.item.index].getAttribute('data-img')
  if img == "undefined"
    img = ''
  prnt = $(e.currentTarget).closest('.band').children('.fullimage')
  prnt = $(e.currentTarget).closest('.band').children('.fullimage')
  active = prnt.children('.owlimg-active')
  prev = prnt.children('.owlimg-prev')
  prev.removeClass('fadeout')
  active.css('background-image','url('+img+')')
  active.addClass('fadein')
  if(!img || typeof img == 'undefined' || img == 'undefined')
    prev.addClass('fadeout')
  setTimeout ->
    prev.css('background-image','url('+img+')')
    active.removeClass('fadein')
  ,1500

clists = document.querySelectorAll('.dl_panel')
fullimgs = document.querySelectorAll('.fullimage')
sliders = document.querySelectorAll('.slidein')

carouselIsPlaying = []

# $(document).ready ->

mcm = document.querySelector('.mailchimp-modal')
if mcm
  mcm.className = mcm.className.replace(/ ?loading ?/,'')

checkSliders()

['scroll','load','resize'].forEach (type)->
  if fullimgs.length
    window.addEventListener type,checkScroll
  if sliders.length
    window.addEventListener type,checkSliders

# scroll into view if body is shorter than viewport
body = document.body
html = document.documentElement
bodyheight = Math.max(body.scrollHeight,body.offsetHeight,html.clientHeight,html.scrollHeight,html.offsetHeight)
if bodyheight <= window.innerHeight
  checkSliders()

setTimeout ->
  window.scrollTo(window.scrollX, window.scrollY+1)
  window.scrollTo(window.scrollX, window.scrollY-1)
  document.querySelector('#page-top').scrollIntoView({behavior:'smooth'})
,300

fi = document.querySelector('.fullimage')
fic = document.querySelector('.fullimage_check')

if(fi && fic)
  equalise()

[].forEach.call clists,(clist)->
  $(clist).addClass('owl-carousel')
  $(clist).owlCarousel({items:1,autoplay:true,autoplayTimeout:6000,loop:true,dots:true,autoplayHoverPause:true,onInitialized:updateImage,dotsContainer:$(clist).closest('.band').find('.owl-dots-outer')})
  $(clist).on 'changed.owl.carousel',updateImage  
  $(clist).trigger('stop.owl.autoplay')

